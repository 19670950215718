.index {
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 7rem;
}

.index__content {
    padding: 1rem;
    height: 30vh;
    position: relative;
}

.image__image, .index__image-img {
    display: none;
}

.index__title {
    font-size: 38.4px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    word-break: break-word;
}

.index__link-popup {
    display: none;
}

.index__link-mobile {
    background: #000;
    font-size: 19.2px;
    padding: .5rem;
    text-transform: lowercase;
}

@media (min-width: 800px) {

    .index {
        padding: 1rem 5%;
        padding-top: 6rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto, auto);
    }

    .index__content {
        height: 100%;
        padding: 2rem 1rem;
    }

    .index__link-mobile {
        display: none;
    }

    .index__title {
        position: relative;
        top: initial;
        left: initial;
        transform: translate(0,0);
        font-size: 1.4rem;
        margin-bottom: .8rem;
    }

    .index__image {
        display: block;
        width: 85%;
        position: relative;
        margin: 0 auto;
    
        img {
            display: block;
            width: 100%;
        }
    }

    .index__link-popup {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.7rem;
        background: #000;
        padding: 0 .2rem;
        opacity: 0;
        transition: opacity 100ms ease-in-out;

        a {
            font-family: 'trois-mille', Helvetica, sans-serif;
        }
    }

    .index__image img:hover ~ .index__link-popup, .index__link-popup:hover {
        opacity: 1;
    }

    .index__image:hover {
        box-shadow: 7px 7px 0 0 #000;
    }
}

@media (max-width: 600px) {
    .index {
        margin-top: -2rem;
    }
}