.footer {
    width: 100vw;
    height: 4.1rem;
    background: #D8D8D8;
    text-align: center;
}


@media (min-width: 800px) {
    .footer {
        height: 2.1rem;
    }
}

