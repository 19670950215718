.contact-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 180px;
  padding-bottom: 50px;

  .tier-grid {
    display: flex;
    flex-direction: column;
    max-width: 1200px;

    h1 {
      text-align: center;
      margin: 0 auto;
      
      &:nth-child(2) {
        margin-bottom: 20px;
      }
    }
    
    .tier-grid-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 15px;
      gap: 15px;

      @media (max-width: 1000px) {
        grid-template-columns: repeat(1, 1fr);
      }

      &__tier {
        padding: 20px;
        border: 1px solid #000000;
        border-radius: 5px;
        position: relative;
        padding-bottom: 65px;

        .email-btn {
          font-size: 12px;
          text-decoration: underline;
          cursor: pointer;
          position: absolute;
          bottom: 20px;
          left: 20px;
          margin: 0;
        }
  
        h3 {
          font-size: 25px;
          margin: 0;
          margin-bottom: 5px;
        }
  
        ul {
          margin-top: 15px;
          margin-left: 17px;
          font-size: 14px;

          li {
            margin-top: 10px;
            position: relative;

            span {
              font-size: 20px;
              position: absolute;
              left: -20px;
              top: 0;
              color: green;
            }
          }
        }
      }
    }

    .cta-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @media (max-width: 1000px) {
        flex-direction: column;
      }

      button, a:link, a:visited {
        padding: 15px;
        border: none;
        border-radius: 4px;
        background: #000000;
        color: #ffffff;
        font-family: 'trois-mille', Helvetica, sans-serif;
        margin-top: 30px;
        text-transform: uppercase;
        font-size: 20px;
        cursor: pointer;
        text-align: center;

        @media (max-width: 1000px) {
          width: 100%;
          margin-top: 15px;
        }
  
        &:hover {
          opacity: 0.9;
        }
      }

      a:link, a:visited {
        margin-right: 20px;

        @media (max-width: 1000px) {
          margin-right: 0;
        }
      }
    }
  }
  
  .form-wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.9);
    padding-top: 40px;

    h1 {
      color: #ffffff;
      margin-bottom: 20px;
    }
  }

  h2 {
    margin: 0;
    text-align: center;
    a:link, a:visited {
      color: #000000;
      font-family: 'trois-mille', Helvetica, sans-serif;
      text-decoration: underline;
    }

    &:nth-child(2) {
      margin-top: 15px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    position: relative;
    padding: 15px;

    .form-close {
      position: absolute;
      top: -12px;
      right: -50px;
      width: 35px;
      height: 35px;
      cursor: pointer;

      @media (max-width: 1000px) {
        top: 0px;
        right: 15px;
      }

      &__bar {
        width: 35px;
        height: 1.5px;
        background-color: #ffffff;
        transform: rotate(-45deg);
        position: absolute;
        top: 15px;
        left: 0;

        &:nth-child(2) {
          transform: rotate(45deg);
        }
      }
    }

    label, input, textarea {
      font-family: 'trois-mille', Helvetica, sans-serif;
    }

    input {
      padding: 15px;
      border-radius: 4px;
      outline: #000000;
      border: solid 1px #000000;
    }

    textarea {
      min-height: 300px;
      padding: 15px;
      outline: #000000;

      @media (max-width: 1000px) {
        min-height: 200px;
      }
      @media (max-height: 780px), (min-width: 701px) {
        min-height: 200px;
      }
      @media (max-width: 700px) {
        min-height: 120px;
      }
    }

    label {
      margin-top: 30px;
      margin-bottom: 5px;
      color: #ffffff;
    }

    .cta-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    button {
      padding: 15px;
      border: none;
      border-radius: 4px;
      background: #ffffff;
      color: #000000;
      font-family: 'trois-mille', Helvetica, sans-serif;
      margin-top: 30px;
      text-transform: uppercase;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.carousel-container {
  width: 100vw;
  height: 100vh;
}

.swiper-slide {
  width: 100% !important;
}

.swiper-custom {
  height: 700px;

  @media (max-width: 1200px) {
    height: 580px;
  }
  @media (max-width: 900px) {
    height: 520px;
  }
  @media (max-width: 735px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    height: 250px;
  }
  @media (max-width: 400px) {
    height: 200px;
  }
}

.thank-you-message {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    &:nth-child(2) {
      margin-bottom: 280px;
    }
  }
}