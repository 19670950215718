.about {
    width: 100%;
    height: 100%;
    margin-top: 2rem;

    .project {
        width: 100%;
        height: 89vh;
        position: sticky;
        top: 0;

        .project__link-popup {
            white-space: nowrap;
        }

        h4 a:link, h4 a:visited {
            color: #000;
            font-family: 'trois-mille', Helvetica, sans-serif;
        }

        .project__description-contact {
            width: 35vw;
            min-width: 400px;
            margin: 1rem auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            p, a:link, a:visited {
                color: #000;
                font-family: 'trois-mille', Helvetica, sans-serif;
            }

            p:not(:nth-child(1)) {
                margin-left: 1rem;
            }
        }
    }


    .skills {
        position: sticky;
        top:0;
        height: 100%;
        min-height: 700px;
        background: #000;
        color: #fff;

        h1 {
            text-align: center;
            padding: 1.5rem;
            font-size: 2.5rem;
        }

        &__list, &__contact {
            width: 35rem;
            margin: 0 auto;

            h3 {
                margin: 10px;
            }
        }

        &__list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__contact {
            line-height: 1;
            
            a {
                text-transform: lowercase;
            }
        }
    }
}

@media (max-width: 600px) {
    .about {
        margin-top: 4rem;

        .skills {
            padding: 1rem;
            &__list, &__contact {
                width: 100%;
    
                h3 {
                    font-size: 1rem;
                }
            }
        }
    }
}

.contact-page-image {
    @media (max-height: 725px) {
        width: 38vw;
    }
}



// .about {
//     width: 100%;
//     height: 100%;
//     margin-top: 8.5rem;
//     padding: 0 5%;
//     display: flex;
//     flex-direction: column;
    
//     &__info {
//         padding-bottom: 1rem;
//         background: #fff;
//         font-size: 2.3rem;
//         color: #000;

//         h3 {
//             margin-bottom: 0;
//         }

//         h5 {
//             font-size: 1rem;
//             margin-top: 0;
//         }
//     }

//     &__contact {
//         color: #000;
//         padding-top: 0rem;

//         p {
//             margin-bottom: .5rem;
//             font-size: 1.2rem;
//         }

//         a:link, a:visited {
//             color: #000;
//         }
//     }
// }


// @media (max-width: 1000px) {
//     .about__info {
//         font-size: 1.7rem;

//         h5 {
//             font-size: 1rem;
//         }
//     }
// }

// @media (max-width: 550px) {
//     .about {
//         margin-top: 8rem;
//     }

//     .about__info {
//         font-size: 1.1rem;
//         padding-bottom: 0;
//         overflow-y: scroll;

//         h5 {
//             font-size: .8rem;
//         }
//     }

//     .about__contact {
//         padding-top: 0;
        
//         p {
//             font-size: 1rem;
//         }
//     }
// }

// @media (min-width: 1500px) {
//     .about__info {
//         font-size: 2.9rem;

//         h5 {
//             font-size: 1.5rem;
//         }
//     }

//     .about__contact {
        
//         p {
//             font-size: 1.8rem;
//         }
//     }
// }