html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    padding: 0;
    margin: 0;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#footer {
    margin-top: auto;
}

h1, h2, h3, h4, h5 {
    font-family: 'trois-mille', Helvetica, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}
p, li, a {
    font-family: 'Roboto Mono', monospace;
}

p {
    margin: 0;
}

h1 {
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

a:link, a:visited {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

