//variables
$paragraph-size: 1.1rem;

//mixins

@mixin nav-info {
    position: fixed;
    padding: .3rem;
    background: #000;
    color: #fff;
    font-size: $paragraph-size;
    font-family: 'Roboto Mono', monospace;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

// mobile

.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.navbar {
    position: fixed;
    top: 0;
    background: #000;
    color: #fff;
    width: 100%;
    height: 3rem;
    z-index: 999;
    @include flex-row;

    &__under {
        position: absolute;
        top: 3rem;
        width: 100%;
        background: #000;
        color: #fff;
        display: flex;
        flex-direction: column;
    }

    &__dropdown {
        position: relative;
        opacity: 0;
        height: 0;
        top: -10rem;
        font-size: $paragraph-size;
        padding: 0;
        transition: opacity 700ms ease-in-out, height 500ms ease-in-out;

        &.show {
            opacity: 1;
            height: 100%;
            top: initial;
        }

        .dropdown__list {
            padding-left: 5%;
            li {
                padding-bottom: .5rem;
            }
    
            li:last-child {
                margin-bottom: .5rem;
            }
        }
    }

    &__name {
        position: absolute;
        width: 100%;
        background: #000;
        text-align: center;
        border-top: 1.5px solid #fff;
        color: #fff;
        font-size: 1.6rem;
        z-index: 1;
        transition: top 200ms ease-in-out;
    }

    &__list {
        display: none;
    }
}

.hamburger {
    width: 45px;
    height: 28px;
    position: relative;
    z-index: 999;
    margin: 1rem auto;
    margin-top: 1.4rem;
    cursor: pointer;

    &__bar {
        position: absolute;
        width: 40px;
        height: 3px;
        background: #fff;
        left: 0;
        transition: all .30s ease-in-out;

        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(1) {
            top: 10px;
        }
        &:nth-child(2) {
            top: 20px;
        }
        .open > & {
            transition: all .30s ease-in-out;
        }
        .open > &:first-child {
            top: .7rem;
            transform: rotate(135deg);
            transition: all .30s ease-in-out;
        }
        .open > &:nth-child(2) {
            opacity: 0;
            left: -60px;
            transition: all .30s ease-in-out;
        }
        .open > &:nth-child(3) {
            top: .7rem;
            transform: rotate(-135deg);
            transition: all .30s ease-in-out;
        }
    }
}

.nav__location {
    @include nav-info;
    bottom: 0rem;
    left: 5%;
}

.nav__location::before {
    content: 'Developer, Los Angeles, CA';
}

.nav__contact {
    @include nav-info;
    bottom: 2rem;
    left: 5%;
    text-align: center;
    overflow-x: hidden;
    transition: all 100ms ease-in-out;
}

// Desktop

@media (min-width: 750px) {

    .navbar {
        left: 5%;
        width: 30rem;
        height: 2rem;

        &__under {
            top: 2rem;
        }

        &__list {
            width: 100%;
            height: 100%;
            padding: 0 .3rem;
            font-size: $paragraph-size;
            @include flex-row;
            justify-content: space-between;
            z-index: 999;
            background: #000;
        }

        &__visible {
            top: 0;
            opacity: 1;
        }
    
        &__hidden {
            top: -4rem;
        }
    }

    .nav__contact {
        bottom: initial;
        left: initial;
        top: 0;
        right: 5%;
    }

    .nav__location::before {
        content: '';
    }

    .nav__location-initial::before {
        content:'Developer, Los Angeles, CA';
    }
    
    .nav__location-name::before {
        content:'Brent Ahrens, Developer, Los Angeles, CA';
    }

    .hamburger {
        display: none;
    }
}

@media (max-width: 500px) {
    .navbar__name {
        font-size: 1.2rem;
    }
}

@media (max-width: 350px) {
    .navbar__name {
        font-size: 1rem;
    }
}