.project {
    height: 100%;
    width: 100%;
}

.project__inner {
    position: sticky;
    top: 0;
    height: 95vh;
    min-height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.project__content {
    width: 95%;
    position: relative;

    .project__title {
        font-size: 2.4rem;
    }
}

.project__content-top {
    margin-top: 4rem;
}

.project__image {
    display: block;
    position: relative;
    width: 100%;
    max-width: 100%;
    max-width: 100%;
    height: auto;
    margin: 1rem auto;

    img {
        display: block;
        width: 100%;
        object-fit: cover;
    }
}

.project__tech {
    margin-bottom: 0;
}

.project__description, h4 {
    font-size: 1.2rem;
}

.project__link-popup {
    display: none;
}

.project__link-mobile {
    display: block;
    position: absolute;
    bottom: 0;
    background: #000;
    padding: 1rem;
    width: 100%;
    display: none;

    a {
        font-family: 'trois-mille', Helvetica, sans-serif;
    }
}

@media (min-width: 800px) {

    .project__inner {
        position: sticky;
        top: 0;
        height: 95vh;
        min-height: 700px;
    }

    .project__content {
        max-width: 1200px;

        .project__title {
            font-size: 51.2px;
        }
    }
    
    .project__link-popup {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3.6rem;
        background: #000;
        padding: 0 .5rem;
        opacity: 0;
        transition: opacity 100ms ease-in-out;

        a {
            font-family: 'trois-mille', Helvetica, sans-serif;
        }
    }

    .project__image, .project__description {
        width: 58vw;
        min-width: 400px;
        margin: 1rem auto;
    }

    .project__description {
        margin-top: 5px;
    }

    .project__image img:hover + .project__link-popup, .project__link-popup:hover  {
        opacity: 1;
    }

    .project__image:hover {
        box-shadow: 10px 10px 0 0 #000;
    }

    .project__link-mobile {
        display: none;
    }
}

@media (max-width: 1100px) {
    .project__link-popup {
        font-size: 2.6rem;
    }
}

@media (min-width: 1400px) {
    .project__link-popup {
        font-size: 4rem;
    }
}

@media (max-width: 500px) {
    .project__inner {
        height: 90vh;
    }

    .project__content {
       overflow-x: hidden;

        .project__title {
            font-size: 30px;
        }
    }

    .project__content {
        margin-top: 0rem;
    }

    .project__content-top {
        margin-top: 2.2rem;
    }

    .project__link-mobile {
        font-size: 1rem;
    }

    .project__description {
        font-size: 1rem;
    }
}

@media (max-width: 350px) {
    .project__link-mobile {
        font-size: 1.3rem;
    }
}
